<!-- 我的定制 -->
<template>
  <div class="module">
	  <div class="module-left-wrap">
		<div class="module-lf">
		  <img :src="CustomList.img" class="module-lf-img" />
		</div>
		<div class="module-center">
		  <div class="title">
		    {{ CustomList.music_title }}
		  </div>
		  <div class="date">
		    {{$t('deadline')}} : {{ CustomList.end_time }}
		  </div>
		  <div class="time">
		    {{$t('issuedate')}} : {{ CustomList.release_time }}
		  </div>
		</div>
	  </div>
    
    <!-- 未完成 -->
    <div class="module-rg" v-if="type == 'unfinished'">
      <div class="code">{{$t('customcoding')}} : {{ CustomList.custom_code }}</div>
	  <div class="right-data">
		  <div class="icon2" @click="custom_music(CustomList)">
		    <Icon name="icon-7key-musicsousuowenjian" theme-color="iconColorAll" width="18" height="18"></Icon>
		  </div>
		  <div v-if="$store.state.identity == 3" class="btn" @click="toup_custom(CustomList.code)" style="background-color:#3370FF">
		    <div class="btn-p">{{$t('submitwork')}} </div>
		  </div>
	  </div>
      
    </div>
    <!-- 已完成 -->
    <div class="module-rg" v-if="type == 'complete'">
      <div class="code">{{ CustomList.code }}</div>
	  <div class="right-data">
		  <div class="icon" @click="custom_music(CustomList)">
		    <Icon name="icon-7key-musicsousuowenjian" theme-color="iconColorAll" width="18" height="18"></Icon>
		  </div>
		  <div class="icon1">
		    <Icon name="icon-7key-musicbf" theme-color="iconColorAll" width="18" height="18"></Icon>
		  </div>
	  </div>
      
    </div>
    <!-- 已取消 -->
    <div class="module-rg" v-if="type == 'canceled'">
      <div class="code">{{ CustomList.code }}</div>
	  <div class="right-data">
		<div class="icon2" @click="custom_music(CustomList)">
		  <Icon name="icon-7key-musicsousuowenjian" theme-color="iconColorAll" width="18" height="18"></Icon>
		</div>
		<div class="btn" v-if="$store.state.identity == 3">
		  <div class="btn-p1">{{$t('submitwork')}} </div>
		</div>
	  </div>
      
    </div>
  </div>
</template>

<script>

export default {
  props: {
    CustomList: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
    },
  },
  methods: {
	  // 查看信息
	  custom_music(data){
		  this.$emit('customMusic',data)
	      // this.$router.push({
	      //     path:'/personal/customMusic',
	      //     query:{
	      //         custom_code:custom_code
	      //     }}
	      // )
		  
	  },
	  // 提交作品
  	toup_custom(custom_code){
  	    // this.$router.push({
  	    //     path:'/upopus',
  	    //     query:{
  	    //         custom_code:custom_code
  	    //     }}
  	    // )
  	}
  },


}
</script>

<style lang="less" scoped>
.module {
  width: 100%;
  height: 128px;
  background:@recommendMusicianListBGColor;
  border-radius: 6px;
  margin-top: 16px;
  display: flex;
  .module-left-wrap{
	  display: flex;
	  // justify-content: space-between;
	  width: 70%;
	.module-lf {
	  // width: 10%;
	  height: 128px;
	
	  .module-lf-img {
	    width: 96px;
	    height: 96px;
	    border-radius: 6px;
	    background-color: #fff;
	    margin-left: 16px;
	    margin-top: 16px;
	  }
	
	}
	
	.module-center {
	  // width: 70%;
		width: calc(100% - 128px);
	  height: 128px;
	
	  .title {
	    width: 138px;
	    height: 22px;
	    font-size: 16px;
	    font-family: PingFangSC-Medium, PingFang SC;
	    font-weight: 500;
	    color: @demoitemsongnameColor;
	    line-height: 22px;
	    margin-left: 16px;
	    margin-top: 16px;
	  }
	
	  .date {
	    // width: 190px;
	    height: 20px;
	    font-size: 14px;
	    font-family: PingFangSC-Regular, PingFang SC;
	    font-weight: 400;
	    color: @demoitemsongMusicianColor;
	    line-height: 20px;
	    margin-left: 16px;
	    margin-top: 24px;
	  }
	
	  .time {
	    // width: 190px;
	    height: 20px;
	    font-size: 14px;
	    font-family: PingFangSC-Regular, PingFang SC;
	    font-weight: 400;
	    color: @demoitemsongMusicianColor;
	    line-height: 20px;
	    margin-left: 16px;
	    margin-top: 8px;
	  }
	}
  }

  

  .module-rg {
    width: 30% ;
    height: 128px;
	padding-right: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
    // position: relative;

    .code {
      // width: 120px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @demoitemsongMusicianColor;
      line-height: 20px;
      // margin-top: 16px;
      // position: absolute;
      // right: 16px;
	  display: flex;
	  justify-content: flex-end;
	  padding-right: 6px;
    }
	.right-data{
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: flex-end;
		.icon {
		  width: 32px;
		  height: 32px;
		  background:@demoitemtabsBGColor;
		  opacity: 0.6;
		  right: 63px;
		  bottom: 16px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  // position: absolute;
		  border-radius: 20px;
		
		}
		.icon1 {
		  width: 32px;
		  height: 32px;
		  background:@demoitemtabsBGColor;
		  opacity: 0.6;
		  // position: absolute;
		  right: 16px;
		  bottom: 16px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  // position: absolute;
		  border-radius: 20px;
		  margin-left: 20px;
		
		}
		.icon2 {
		  width: 32px;
		  height: 32px;
		  background:@demoitemtabsBGColor;
		  opacity: 0.6;
		  // position: absolute;
		  right: 116px;
		  bottom: 16px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  // position: absolute;
		  border-radius: 20px;
		  
		
		}
		
		
		.btn {
		  // width: 88px;
		  height: 36px;
		  background: rgba(255, 255, 255, 0.6);
		  border-radius: 6px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  margin-left: 20px;
		  // position: absolute;
		  // right: 16px;
		  // bottom: 16px;
		  padding: 0 10px;
		
		  .btn-p {
		    // width: 56px;
		    height: 20px;
		    font-size: 14px;
		    font-family: PingFangSC-Semibold, PingFang SC;
		    font-weight: 600;
		    color: #FFFFFF;
		    line-height: 20px;
			white-space: nowrap;
		  }
		  .btn-p1{
			height: 20px;
			font-size: 14px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: @demoitemsongMusicianColor;
			line-height: 20px;
			white-space: nowrap;
		  }
		
		}
	}

    

  }

}
</style>